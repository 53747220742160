<template>
  <v-card>
    <v-card-title :class="`primary white--text headline`">
      <span>
        {{$t('updateDeviceRegistration')}}
      </span>
      <v-spacer/>
      <Icon
      @icon-clicked="$emit('cancel')"
      :tooltipText="$t('cancel')"
      dataTestId="update-device-registration-cancel-button"
      iconColor="white"
      icon="mdi-close"
      :small="false"
      large
      />
    </v-card-title>
    <v-card-text class="mt-6">
      <FormWrapper @submit="saveChanges">
        <v-container fluid>
          <v-row v-if="device" class="black--text" style="font-size: 14px;">
            <v-col cols="12">
              <span class="subtitle" style="font-size: 16px;">
                {{$t('deviceInformation')}}
              </span>
            </v-col>
            <v-col cols="12" md="6">
              <v-icon small>mdi-account</v-icon>
              <span>
                {{device.accountName}}
              </span>
            </v-col>
            <v-col cols="12" md="6">
              <Icon :icon="TicketOrigin.forInt(device.application).icon" :tooltipText="TicketOrigin.fromInt(device.application)"/>
              <span>
                {{device.deviceID}}
              </span>
            </v-col>
            <v-col cols="12" class="mt-6">
              <DatePicker
              dateLabel="Expires"
              dataTestId="device-expirdate"
              :startDate="expirationDate"
              :mustBeAfter="expirationDate"
              @date-picked="expirationDateChosen"/>
            </v-col>
          </v-row>
          <v-row v-else justify="center" align="center">
            <v-progress-circular class="pa-12 ma-12" indeterminate color="primary"/>
          </v-row>
        </v-container>
      </FormWrapper>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions } from 'vuex'
import { localToUTC, utcToLocalDate } from '@/utils/DateFormatter.js'
import { TicketOrigin } from '@/utils/Enumerations'

export default {
  name: 'UpdateDeviceRegistration',
  props: {
    registrationId: {
      type: Number,
      required: true
    }
  },

  components: {
    Icon: () => import('@/components/helper/Icon.vue'),
    DatePicker: () => import('@/components/helper/DatePicker.vue'),
    FormWrapper: () => import('@/components/core/FormWrapper.vue')
  },

  data: () => ({
    TicketOrigin,
    device: undefined,
    expirationDate: undefined
  }),

  async created () {
    const device = await this.getRegistration(this.registrationId)
    this.expirationDate = utcToLocalDate(device.expiresOn)
    this.device = device
  },

  methods: {
    ...mapActions('device-registration', ['getRegistration', 'updateRegistration']),
    expirationDateChosen (date) {
      this.expirationDate = date
    },

    async saveChanges () {
      const updateRequest = {
        identifier: this.device.identifier,
        deviceRegistrationId: this.device.deviceRegistrationId
      }
      updateRequest.updatedExpiresOn = localToUTC(this.expirationDate)
      await this.updateRegistration(updateRequest)
      this.$emit('registration-updated')
    }
  }
}
</script>
