import { SasTokenLocations } from './LocalStorageActor'
import { sasNeedsRefresh, createResourceUrl } from './SasHelpers'
import ApiServer from './api/ApiServer'
import { BlobServiceClient } from '@azure/storage-blob'

class GatePassClient {
  async getContainerClient () {
    await this.initConnection()
    return this.containerClient
  }

  getEndpoint () {
    return 'companyinfo/blobinfo/gatepasses'
  }

  async initConnection () {
    const sasCapsule = JSON.parse(localStorage.getItem(SasTokenLocations.GATE_PASS_SAS))
    if (sasCapsule === null || sasNeedsRefresh(sasCapsule.data[0].blobSasUri)) {
      const endpoint = this.getEndpoint()
      const response = await ApiServer.get(ApiServer.urlFor(endpoint))
      localStorage.setItem(SasTokenLocations.GATE_PASS_SAS, JSON.stringify(response))
    }

    if (this.containerClient === undefined) {
      const storedResponse = JSON.parse(localStorage.getItem(SasTokenLocations.GATE_PASS_SAS))
      this.container = storedResponse.data[0].container
      this.location = storedResponse.data[0].location
      this.client = new BlobServiceClient(storedResponse.data[0].blobSasUri)
      this.containerClient = this.client.getContainerClient(this.container)
    }
  }

  getBlobLocation (contractId) {
    return `${this.location}/${contractId}/`
  }

  async getGatePass (contractId) {
    try {
      const containerClient = await this.getContainerClient()
      const blobLocation = this.getBlobLocation(contractId)
      const blobs = containerClient.listBlobsByHierarchy('/', { prefix: blobLocation })
      const blobItem = await blobs.next()
      const blobUrl = createResourceUrl(JSON.parse(localStorage.getItem(SasTokenLocations.GATE_PASS_SAS)).data[0].blobSasUri, this.container, blobItem.value.name)
      return blobUrl.href
    } catch (e) {
      // Check for no gatepass
      if (e.name !== 'TypeError') {
        console.error(e)
        throw e
      }
    }
  }

  async deleteGatePass (gatePassUrl) {
    try {
      const containerClient = await this.getContainerClient()
      const urlString = (gatePassUrl instanceof URL)
        ? decodeURI(gatePassUrl.pathname)
        : decodeURI(new URL(decodeURI(gatePassUrl)).pathname)
      const blobName = urlString.replace(/^\//, '').split('/').slice(1).join('/')
      const blockBlobClient = containerClient.getBlockBlobClient(blobName)
      await blockBlobClient.deleteIfExists()
    } catch (e) {
      console.error(e)
      throw e
    }
  }

  async uploadGatePass (contract, gatePassFile) {
    try {
      const containerClient = await this.getContainerClient()
      const fullBlobLocation = this.getBlobLocation(contract.contractId) + gatePassFile.name
      const blockBlobClient = containerClient.getBlockBlobClient(fullBlobLocation)
      const options = {
        blobHTTPHeaders: {
          blobContentType: gatePassFile.type
        }
      }
      await blockBlobClient.uploadData(gatePassFile, options)
    } catch (e) {
      console.error(e)
      throw e
    }
  }
}

export const gatePassClient = new GatePassClient()
